<template>
  <div class="Client font">
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="retailerS"
          :breadcrumb="[
            {
              label: 'retailers',
              url: 'http://localhost:8080/super-retailer/retailers',
            },
            { label: 'retailers-List' },
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card radius"
              style="box-shadow: 0px 0px 10px gray; margin-left: 3px; height: 100%"
            >
              <div class="card-body">
                <!-- start list area  -->
                <div class="row">
                  <h4><b style="color: #00364f; font-weight: bolder">Retailers </b></h4>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top: 7px"
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm font">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">&nbsp;&nbsp;+&nbsp;&nbsp;</th>
                          <th class="text-truncate">Code</th>
                          <th class="text-truncate">Name</th>
                          <th class="text-truncate">Mobile No.</th>
                          <th class="text-truncate">Email Id</th>
                          <!-- <th>Services</th> -->
                          <th class="text-truncate">Kyc Status</th>
                          <th class="text-truncate">Login Status</th>
                          <th class="text-truncate">Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f; font-size: 14px"
                            v-for="(retailerinfo, index) in retailers"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 30px; height: 30px; border-radius: 2px"
                                :src="retailerlogofinder(retailerinfo)"
                                alt=""
                              />
                            </td>
                            <td v-if="retailerinfo">{{ retailerinfo.code }}</td>
                            <td v-if="retailerinfo">
                              {{ retailerinfo.name }}
                            </td>
                            <td v-if="retailerinfo">{{ retailerinfo.phone }}</td>
                            <td v-if="retailerinfo">{{ retailerinfo.email }}</td>

                            <td>
                              <span v-if="retailerinfo.retailer_kyc">
                                {{ retailerinfo.retailer_kyc.kycStatus }}
                              </span>
                            </td>
                            <td>
                              <p
                                v-if="retailerinfo.loginStatus == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                            <td>
                              <div class="btn-group btn-group-sm" role="group">
                                <router-link
                                  :to="`retailer-profile-view/${retailerinfo.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>
                                <router-link
                                  to="#"
                                  @click="retailerkycValueAsign(retailerinfo)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kycModal"
                                  class="btn btns btn-sm text-dark"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: yellow;
                                  "
                                >
                                  <font-awesome-icon icon="file" />
                                </router-link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="kycModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">{{ kycModalHeading }}</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row" v-if="msgImgError">
            <div class="col">
              <div class="alert alert-danger p-1" role="alert">
                {{ msgImgError }}
              </div>
            </div>
          </div>

          <form
            @submit.prevent="
              retailerKycVal == 'false' ? retailerkycCreate() : retailerkycUpdate()
            "
          >
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                <th>+</th>
                <th>Documents</th>
                <th class="text-end">Action</th>
              </tr>
              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Retailer Aadhaar Front</b>
                    <input
                      @change="uploadAdharFront"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="AdharFront"
                      type="file"
                      accept="image/*"
                    />
                    <input
                      type="number"
                      v-model="form.aadharNo"
                      class="form-control"
                      placeholder="Adhar Number"
                      style="height: 30px"
                      @change="lenthheker($event, 'clientKycAddar')"
                      required
                    />
                    <span style="color: #f21000" id="clientKycAddar"></span>
                    <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.aadharNo">{{
                          form.errors.error.aadharNo[0]
                        }}</span></span
                      ></small
                    >
                  </td>
                  <td class="text-truncate text-end">
                    <label for="AdharFront">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Retailer Aadhaar Back</b>
                    <input
                      @change="uploadAdharBack"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="AdharBack"
                      type="file"
                      accept="image/*"
                    />
                  </td>
                  <td class="text-truncate text-end">
                    <label for="AdharBack">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Retailer Pan</b>
                    <input
                      @change="uploadPanPhoto"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="panphoto"
                      type="file"
                      accept="image/*"
                    />
                    <div class="form-group">
                      <!-- <label>Business Pan <span style="color: #f21000">*</span></label> -->
                      <div v-if="panVarifyingStatus == 'true'">
                        <input
                          required
                          disabled
                          type="tel"
                          maxlength="10"
                          v-model="form.panNo"
                          class="form-control"
                          placeholder="Pan Number"
                          style="text-transform: uppercase; height: 30px"
                          @change="businessPanVeryfied($event)"
                          @keydown="nameKeydown($event)"
                        />
                      </div>
                      <div v-if="panVarifyingStatus == 'false'">
                        <input
                          required
                          type="tel"
                          maxlength="10"
                          v-model="form.panNo"
                          class="form-control"
                          placeholder="Pan Number"
                          style="text-transform: uppercase; height: 30px"
                          @change="businessPanVeryfied($event)"
                          @keydown="nameKeydown($event)"
                        />
                      </div>

                      <span style="color: #f21000" id="businessPanVeryfiedAdmin"></span>
                      <small style="color: red" v-if="form.errors"
                        ><span v-if="form.errors.error"
                          ><span v-if="form.errors.error.pan">{{
                            form.errors.error.pan[0]
                          }}</span></span
                        ></small
                      >
                    </div>
                  </td>
                  <td class="text-truncate text-end">
                    <label for="panphoto">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
import Form from "vform";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
// var _ = require('lodash');
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "retailers",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      msgImgError: "",
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      extensionkycPassbookPhoto: "",
      loading: false,
      retailers: [],
      retailer_id: null,
      retailerKycs: [],
      kycModalHeading: "",
      // retailer kyc form vars
      placeholderImg: this.$store.state.placeholderImg,
      retailerKyc_id: null,
      aadharNoForKyc: "",
      accountNoForKyc: "",
      panNoForKyc: "",
      photo: "",
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      retailerKycVal: "false",
      form: new Form({
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
        errors: {},
      }),
      clientKycAddar: "false",
      retailerInformation: {},
      msgPanError: "",
      submitStatus: "false",
      panVarifyingStatus: "false",
    };
  },
  methods: {
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        $("#businessPanVeryfiedAdmin").html(
          "<small>special characters are not allowed</small>"
        );
        e.preventDefault();
      } else {
        $("#businessPanVeryfiedAdmin").html("<small></small>");
      }
    },
    businessPanVeryfied(event) {
      // var data = this.form.businessPan;
      var data = event.target.value;

      if (data.length == 10) {
        console.log(data.length);
        this.submitStatus = "false";
        $("#businessPanVeryfiedAdmin").html("<small></small>");

        this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "staff/verifypan",
            { panNo: this.form.panNo },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            console.log(res.data.data.data);

            if (res.data.data.data) {
              $("#businessPanVeryfiedAdmin").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.full_name) {
              $("#businessPanVeryfiedAdmin").html("<small></small>");
              this.panVarifyingStatus = "true";

              toast.success("Pan Verified Succesfully", {
                autoClose: 1000,
              });
            } else {
              this.msgPanError = res.data.data.message;
            }
          })
          .finally(() => (this.loading = false));
      } else {
        this.submitStatus = "true";
        $("#businessPanVeryfiedAdmin").html(
          "<small> Pan required minimum 10 Digits</small>"
        );
      }

      // this.panNo = ''
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "clientKycAddar") {
        if (data.length != 12) {
          this.clientKycAddar = "true";

          $("#clientKycAddar").html("<small>Aadhar required minimum 12 letters</small>");
        }
        if (data.length == 12) {
          this.clientKycAddar = "false";
          $("#clientKycAddar").html("<small></small>");
        }
      }
      // done
    },
    // aadharKycApprovedSave() {
    //   var kyc;
    //   if (this.aadharapproveKyc.status == "approve") {
    //     kyc = "Approved";
    //   } else if (this.aadharapproveKyc.status == "reject") {
    //     kyc = "Rejected";
    //   } else if (this.aadharapproveKyc.status == "cancel") {
    //     kyc = "Cancelled";
    //   }
    //   this.loading = true;
    //   this.$axios
    //     .post("staff/approveretailerkyc", this.aadharapproveKyc, {
    //       headers: { Authorization: "Bearer " + localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       console.log(res.data.data);
    //       this.$swal.fire({
    //         position: "top-end",
    //         icon: "success",
    //         title: "Aadhar Kyc " + kyc + " Succesfully",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });

    //       //    this.$router.push({ name: 'retailers' })
    //     })
    //     .finally(() => (this.loading = false));
    // },
    // accountKycApprovedSave() {
    //   var kyc;
    //   if (this.accountapproveKyc.status == "approve") {
    //     kyc = "Approved";
    //   } else if (this.accountapproveKyc.status == "reject") {
    //     kyc = "Rejected";
    //   } else if (this.accountapproveKyc.status == "cancel") {
    //     kyc = "Cancelled";
    //   }
    //   this.loading = true;
    //   this.$axios
    //     .post("staff/approveretailerkyc", this.accountapproveKyc, {
    //       headers: { Authorization: "Bearer " + localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       console.log(res.data.data);
    //       this.$swal.fire({
    //         position: "top-end",
    //         icon: "success",
    //         title: "Bank Account Kyc " + kyc + " Succesfully",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });

    //       //    this.$router.push({ name: 'retailers' })
    //     })
    //     .finally(() => (this.loading = false));
    // },
    // panKycApprovedSave() {
    //   var kyc;
    //   if (this.panapproveKyc.status == "approve") {
    //     kyc = "Approved";
    //   } else if (this.panapproveKyc.status == "reject") {
    //     kyc = "Rejected";
    //   } else if (this.panapproveKyc.status == "cancel") {
    //     kyc = "Cancelled";
    //   }
    //   this.loading = true;
    //   this.$axios
    //     .post("staff/approveretailerkyc", this.panapproveKyc, {
    //       headers: { Authorization: "Bearer " + localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       console.log(res.data.data);
    //       this.$swal.fire({
    //         position: "top-end",
    //         icon: "success",
    //         title: "Pan Kyc " + kyc + " Succesfully",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });

    //       //    this.$router.push({ name: 'retailers' })
    //     })
    //     .finally(() => (this.loading = false));
    // },
    // retailerKycApprovedSave() {
    //   var kyc;
    //   if (this.approveKyc.status == "approve") {
    //     kyc = "Approved";
    //   } else if (this.approveKyc.status == "reject") {
    //     kyc = "Rejected";
    //   } else if (this.approveKyc.status == "cancel") {
    //     kyc = "Cancelled";
    //   }
    //   this.loading = true;
    //   this.$axios
    //     .post("staff/approveretailerkyc", this.approveKyc, {
    //       headers: { Authorization: "Bearer " + localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       console.log(res.data.data);
    //       this.$swal.fire({
    //         position: "top-end",
    //         icon: "success",
    //         title: "retailer Kyc " + kyc + " Succesfully",
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });
    //       this.form = {};
    //       //    this.$router.push({ name: 'retailers' })
    //     })
    //     .finally(() => (this.loading = false));
    // },
    // retailerkycValueApprove(value) {
    //   // alert('this is the retailer id  retailerkycValueAsign' + value.id)
    //   console.log(value);
    //   this.approveKyc.retailer_id = value.id;
    //   this.accountapproveKyc.retailer_id = value.id;
    //   this.panapproveKyc.retailer_id = value.id;
    //   this.aadharapproveKyc.retailer_id = value.id;
    //   this.panNoForKyc = value.retailer_kyc.panNo;
    //   this.accountNoForKyc = value.retailer_kyc.accountNo;
    //   this.aadharNoForKyc = value.retailer_kyc.aadharNo;
    //   // this.form = value.retailer_kyc

    //   if (value.retailer_kyc.panPhoto != null) {
    //     this.panImg =
    //       this.$store.state.imgUrl +
    //       "/retailer/" +
    //       value.id +
    //       "/thumbs/" +
    //       value.retailer_kyc.panPhoto;
    //   } else {
    //     this.panImg = this.$store.state.placeholderImg;
    //   }

    //   if (value.retailer_kyc.passbookPhoto != null) {
    //     this.passBookImg =
    //       this.$store.state.imgUrl +
    //       "/retailer/" +
    //       value.id +
    //       "/thumbs/" +
    //       value.retailer_kyc.passbookPhoto;
    //   } else {
    //     this.passBookImg = this.$store.state.placeholderImg;
    //   }

    //   if (value.retailer_kyc.aadharFrontPhoto != null) {
    //     this.adharFrontImg =
    //       this.$store.state.imgUrl +
    //       "/retailer/" +
    //       value.id +
    //       "/thumbs/" +
    //       value.retailer_kyc.aadharFrontPhoto;
    //   } else {
    //     this.adharFrontImg = this.$store.state.placeholderImg;
    //   }

    //   if (value.retailer_kyc.aadharBackPhoto != null) {
    //     this.adharBackImg =
    //       this.$store.state.imgUrl +
    //       "/retailer/" +
    //       value.id +
    //       "/thumbs/" +
    //       value.retailer_kyc.aadharBackPhoto;
    //   } else {
    //     this.adharBackImg = this.$store.state.placeholderImg;
    //   }
    // },

    uploadAdharFront(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Front  File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharFront").value;
        this.extensionkycAdharFront = fileName.split(".").pop();
        if (
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "png"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharFrontPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG, PNG ,PDF";
        }
        if (this.extensionkycAdharFront == "pdf") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      }
    },
    uploadAdharBack(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Back File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharBack").value;
        this.extensionkycAdharBack = fileName.split(".").pop();
        if (
          this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "png"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharBackPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG, PNG ,PDF";
        }
        if (this.extensionkycAdharBack == "pdf") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      }
    },

    uploadPanPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Pan  File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#panphoto").value;
        this.extensionkycPanPhoto = fileName.split(".").pop();
        if (
          this.extensionkycPanPhoto == "pdf" ||
          this.extensionkycPanPhoto == "jpg" ||
          this.extensionkycPanPhoto == "png"
        ) {
          this.panImg = URL.createObjectURL(e.target.files["0"]);
          this.form.panPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG, PNG ,PDF";
        }
        if (this.extensionkycPanPhoto == "pdf") {
          this.panImg = "/assets/image/pdf.png";
        }
      }
    },
    retailerKycLoad() {
      this.loading = true;
      this.$axios
        .get("staff/retailerkyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.retailerKycs = res.data.data.data;
          console.log(this.retailerKycs);
        })

        .finally(() => (this.loading = false));
    },
    retailerkycValueAsign(value) {
      this.form = {};
      this.msgImgError = "";
      this.adharFrontImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.panImg = this.$store.state.placeholderImg;

      this.retailer_id = value.id;
      if (value.retailer_kyc == null) {
        this.kycModalHeading = "Retailer Kyc";
        this.retailerKycVal = "false";
      } else {
        this.kycModalHeading = "Retailer Kyc";

        this.form = value.retailer_kyc;

        if (this.form.panPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/" +
            this.form.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/" +
              this.form.panPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/thumbs/" +
            this.form.panPhoto
          ) {
            this.panImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/thumbs/" +
              this.form.panPhoto;
          }

          this.extensionkycPanPhoto = this.panImg.split(".").pop();

          if (this.extensionkycPanPhoto == "pdf") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.panImg = this.$store.state.placeholderImg;
        }

        if (this.form.aadharFrontPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/" +
            this.form.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/" +
              this.form.aadharFrontPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/thumbs/" +
            this.form.aadharFrontPhoto
          ) {
            this.adharFrontImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/thumbs/" +
              this.form.aadharFrontPhoto;
          }

          this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

          if (this.extensionkycAdharFront == "pdf") {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharFrontImg = this.$store.state.placeholderImg;
        }

        if (this.form.aadharBackPhoto != null) {
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/" +
            this.form.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/" +
              this.form.aadharBackPhoto;
          }
          if (
            this.$store.state.imgUrl +
            "/retailer/" +
            this.retailer_id +
            "/thumbs/" +
            this.form.aadharBackPhoto
          ) {
            this.adharBackImg =
              this.$store.state.imgUrl +
              "/retailer/" +
              this.retailer_id +
              "/thumbs/" +
              this.form.aadharBackPhoto;
          }

          this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

          if (this.extensionkycAdharBack == "pdf") {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.adharBackImg = this.$store.state.placeholderImg;
        }

        this.retailerKycVal = "true";
      }
    },

    retailerkycCreate() {
      if (this.clientKycAddar == "false") {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("retailer_id", this.retailer_id);
        console.log(formData);
        this.loading = true;
        this.$axios
          .post("staff/retailerkyc", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            console.log(res.data.data);

            toast.success("Kyc Created Succesfully", {
              autoClose: 1000,
            });
            this.form = {};
            this.form.errors = {};
            $("#kycModal").modal("hide");
            this.loadretailers();
            this.adharFrontImg = this.$store.state.placeholderImg;
            this.adharBackImg = this.$store.state.placeholderImg;
            this.panImg = this.$store.state.placeholderImg;
          })
          .catch((error) => {
            this.form.errors = error.response.data;
          })
          .finally(() => (this.loading = false));
      }
    },

    retailerkycUpdate() {
      if (this.clientKycAddar == "false") {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("retailer_id", this.retailer_id);
        this.loading = true;
        this.$axios
          .post(`staff/retailerkyc/${this.form.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            console.log(res.data.data);

            toast.success("Kyc Updated Succesfully", {
              autoClose: 1000,
            });
            this.form = {};
            this.form.errors = {};
            $("#kycModal").modal("hide");
            this.loadretailers();
            this.adharFrontImg = this.$store.state.placeholderImg;
            this.adharBackImg = this.$store.state.placeholderImg;
            this.panImg = this.$store.state.placeholderImg;
          })
          .catch((error) => {
            this.form.errors = error.response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    retailerlogofinder(retailer) {
      var img;
      if (retailer.photo != null) {
        img =
          this.$store.state.imgUrl +
          "/retailer/" +
          retailer.id +
          "/thumbs/" +
          retailer.photo;
        return img;
      } else {
        img = this.$store.state.placeholderImg;
        return img;
      }
    },
    loadretailers() {
      this.loading = true;
      this.$axios
        .get("staff/retailer", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.retailers = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadretailers();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  width: 95%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 2px 8px gray;
  line-height: 10%;
  margin-left: 13px;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.table.table-sm th,
.table.table-sm td {
  padding: 0.2rem 0.5rem rem;
  /* font-weight: 200; */
}
</style>
